/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Navigate, Route, Routes} from 'react-router-dom';
import React, {useEffect, useMemo, useRef} from "react";
import {KaravanApi} from "../api/KaravanApi";
import {
    Bullseye,
    Flex,
    FlexItem,
    Page,
    ProgressStep,
    ProgressStepper,
    Spinner,
    Text, TextContent, TextVariants,
    Tooltip,
    TooltipPosition
} from "@patternfly/react-core";
import CircleIcon from "../CircleLogo";
import {MainLogin} from "./MainLogin";
import {DashboardPage} from "../dashboard/DashboardPage";
import {ProjectsPage} from "../projects/ProjectsPage";
import {ProjectPage} from "../project/ProjectPage";
import {ServicesPage} from "../services/ServicesPage";
import {ContainersPage} from "../containers/ContainersPage";
import {KnowledgebasePage} from "../knowledgebase/KnowledgebasePage";
import {SsoApi} from "../api/SsoApi";
import {useAppConfigStore} from "../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {PageNavigation} from "./PageNavigation";
import {useMainHook} from "./useMainHook";
import {MainDataPoller} from "./MainDataPoller";
import {TemplatesPage} from "../templates/TemplatesPage";
import {EventBus} from "../designer/utils/EventBus";
import {Notification} from "../designer/utils/Notification";
import {LicensePage} from '../smh/license/LicensePage';
import {useLicenseStore} from "../api/LicenseStore";
import {LicenseService} from "../api/LicenseService";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.extend(utc);

export function Main() {

    const [readiness] = useAppConfigStore((s) => [s.readiness], shallow)
    const {getData, getStatuses} = useMainHook();

    const {license,  operation, setLicense} = useLicenseStore();


    const initialized = useRef(false)

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            effect()
        }
    }, [])

    function effect() {
        console.log("Main Start");
        KaravanApi.getAuthType((authType: string) => {
            console.log("authType", authType);
            if (authType === 'oidc') {
                SsoApi.auth(() => {
                    KaravanApi.getMe((user: any) => {
                        LicenseService.getLicense()
                            .then(res => {
                                const [err, _licence] = res;
                                if(err == null && _licence != null) {
                                    setLicense(_licence!!);
                                    getData();
                                }
                            });
                    });
                });
            } else {
                LicenseService.getLicense()
                    .then(res => {
                        const [err, _licence] = res;
                        if(err == null && _licence != null) {
                            setLicense(_licence!!);
                            getData();
                        }
                    });
            }
        });

        console.log("Main End");
    }


    function toast(title: string, text: string, variant: 'success' | 'danger' | 'warning' | 'info' | 'custom') {
        EventBus.sendAlert(title, text, variant)
    }

    function showSpinner() {
        return KaravanApi.authType === undefined || readiness === undefined;
    }

    function showStepper() {
        return readiness !== undefined && readiness.status !== true;
    }

    function getStepper() {
        const steps: any[] = Array.isArray(readiness?.checks) ? readiness.checks : [];
        return (
            <Bullseye className="">
                <Flex direction={{default:"column"}} justifyContent={{default: "justifyContentCenter"}}>
                    <FlexItem style={{textAlign: "center"}}>
                        {CircleIcon()}
                        <TextContent>
                            <Text component={TextVariants.h2}>
                                Waiting for services
                            </Text>
                        </TextContent>
                    </FlexItem>
                    <FlexItem>
                        <ProgressStepper aria-label="Readiness progress" isCenterAligned isVertical >
                            {steps.map(step => (
                                <ProgressStep
                                    key={step.name}
                                    variant={step.status === 'UP' ? "success" : "info"}
                                    isCurrent={step.status !== 'UP'}
                                    icon={step.status !== 'UP' ? <Spinner isInline aria-label="Loading..."/> : undefined}
                                    id={step.name}
                                    titleId={step.name}
                                    aria-label={step.name}
                                >
                                    {step.name}
                                </ProgressStep>
                            ))}
                        </ProgressStepper>
                    </FlexItem>
                </Flex>
            </Bullseye>
        )
    }

    function showMain() {
        return !showStepper() && !showSpinner() && (KaravanApi.isAuthorized || KaravanApi.authType === 'public');
    }

    function isLicenseValid() {
        return license !== undefined
            && license != null
            && !license.expired
            && license.appCode !== undefined
            && license.appCode !== '';
    }

    return (
        <Page className="karavan">
            {showSpinner() &&
                <Bullseye className="loading-page">
                    <Spinner className="spinner" diameter="140px" aria-label="Loading..."/>
                    <Tooltip content="Connecting to server..." position={TooltipPosition.bottom}>
                        <div className="logo-placeholder">{CircleIcon()}</div>
                    </Tooltip>
                </Bullseye>
            }
            {showStepper() && getStepper()}
            {showMain() &&
                <Flex direction={{default: "row"}} style={{width: "100%", height: "100%"}}
                      alignItems={{default: "alignItemsStretch"}} spaceItems={{default: 'spaceItemsNone'}}>
                    <FlexItem>
                        {<PageNavigation/>}
                    </FlexItem>
                    <FlexItem flex={{default: "flex_2"}} style={{height: "100%"}}>
                        <Routes>
                            {/*<Route path="/dashboard" element={<DashboardPage key={'dashboard'}/>}/>*/}
                            {isLicenseValid() && <Route path="/projects" element={<ProjectsPage key={'projects'}/>}/> }
                            {isLicenseValid() && <Route path="/projects/:projectId" element={<ProjectPage key={'project'}/>}/> }
                            {isLicenseValid() && <Route path="/templates" element={<TemplatesPage key={'templates'}/>}/> }
                            {isLicenseValid() && <Route path="/services" element={<ServicesPage key="services"/>}/>}
                            {isLicenseValid() && <Route path="/containers" element={<ContainersPage key="services"/>}/> }
                            {isLicenseValid() && <Route path="/knowledgebase" element={<KnowledgebasePage dark={false}/>}/> }
                            <Route path="/license" element={<LicensePage key={'license'}/>}/>
                            {isLicenseValid() && <Route path="*" element={<Navigate to="/projects" replace/>}/>}
                            {!isLicenseValid() && <Route path="*" element={<Navigate to="/license" replace/>}/>}
                        </Routes>
                    </FlexItem>
                </Flex>
            }
            {!KaravanApi.isAuthorized && KaravanApi.authType === 'basic' &&
                <MainLogin/>}
            <Notification/>
            <MainDataPoller/>
        </Page>
    );
};
