import {SmhApi} from "./SmhApi";
import {
    License
} from "./LicenseModels";
import {
    useLicenseStore
} from "./LicenseStore";

export class LicenseService {
    public static async getLicense() {
        return SmhApi.getLicense()
    }

    public static async createLicense(license: License) {
        return SmhApi.postLicense(license);
    }

    public static async deleteLicense() {
        return await SmhApi.deleteLicense();
    }
}