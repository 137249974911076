export class License {
    key: string = '';
    applicationId: string = '';
    numberToUse: string = '';
    appCode: string = '';
    startDate: string = '';
    endDate: string = '';
    expired: boolean = false;
    message: string = '';
    createdAt: Date = new Date();

    public constructor(key: string);
    public constructor(init?: Partial<License>);
    public constructor(...args: any[]) {
        if (args.length === 1) {
            Object.assign(this, args[0]);
            return;
        } else {
            this.key = args[0];
            this.applicationId = args[1];
            this.numberToUse = args[2];
            this.appCode = args[3];
            this.startDate = args[4];
            this.endDate = args[5];
            this.message = args[6];
            this.createdAt = args[7];
            this.expired = args[8];
            return;
        }
    }
}