import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    DescriptionList,
    DescriptionListDescription,
    DescriptionListGroup,
    DescriptionListTerm,
    PageSection,
    Text,
    TextContent,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    Tooltip,
    Spinner, Bullseye
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import PlusIcon from '@patternfly/react-icons/dist/esm/icons/plus-icon';
import {AddLicenseModal} from './AddLicenseModal';
import {useLicenseStore} from '../../api/LicenseStore';
import {License} from "../../api/LicenseModels";
import {DeleteLicenseModal} from "./DeleteLicenseModal";
import TrashIcon from "@patternfly/react-icons/dist/js/icons/trash-icon";
import {MainToolbar} from "../../designer/MainToolbar";
import {LicenseService} from "../../api/LicenseService";
import {SsoApi} from "../../api/SsoApi";
import {KaravanApi} from "../../api/KaravanApi";

export const LicensePage = () => {

    const {license,  operation, setLicense} = useLicenseStore();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        LicenseService.getLicense()
            .then(res => {
                const [err, _license] = res;
                if(err == null && _license != null) {
                    setLicense(_license!!)
                }
                setLoading(false);
            });
    }, []);

    function getTools() {
        const isLicenseActive = license !== undefined && !license.expired;
        const isUserAdmin = KaravanApi.me?.roles
            .filter((r: string) => ['administrator'].includes(r))
            .length > 0;

        return <Toolbar id="toolbar-group-types">
            <ToolbarContent>
                <ToolbarItem>
                    <Tooltip content={"Add license"} position={"bottom"}>
                        <Button isDisabled={isLicenseActive || !isUserAdmin || loading} icon={<PlusIcon/>}
                                onClick={e =>
                                    useLicenseStore.setState({operation:"add", license: new License()})}
                        >Add License</Button>
                    </Tooltip>
                </ToolbarItem>
                <ToolbarItem>
                    <Tooltip content={"Delete license"} position={"bottom"}>
                        <Button isDisabled={license === undefined || !isUserAdmin || loading} variant="danger" icon={<TrashIcon/>} onClick={e => {
                            useLicenseStore.setState({operation:"delete"})
                        }}>Delete License</Button>
                    </Tooltip>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    }

    function title() {
        return <TextContent>
            <Text component="h2">License Management</Text>
        </TextContent>
    }

    function getLicenseDetails(license?: License) {
        const isLicenseActive = license !== undefined && !license.expired;

        const isUserAdmin = KaravanApi.me?.roles
            .filter((r: string) => ['administrator'].includes(r))
            .length > 0;

        if (operation === "none") {
            if(loading) {
                return (
                    <Bullseye>
                        {<Spinner className="progress-stepper" diameter="80px" aria-label="Loading..."/>}
                    </Bullseye>
                );
            }
            else if(license === undefined) {
                return (
                    <Card className="project-development">
                        <CardBody>
                            <DescriptionList
                                isHorizontal
                                orientation={{
                                    md: 'vertical',
                                    lg: 'horizontal',
                                    xl: 'vertical',
                                    '2xl': 'horizontal'
                                }}
                            >
                                { isUserAdmin && <Text>No existing license found! Please add valid, active license to unlock full feature set.</Text> }
                                { !isUserAdmin && <Text>No existing license found! Please contact administrator to add valid, active license to unlock full feature set.</Text> }
                            </DescriptionList>
                        </CardBody>
                    </Card>
                );
            } else {
                return (
                    <Card className="project-development">
                        <CardBody>
                            {!loading &&
                                <DescriptionList
                                    isHorizontal
                                    orientation={{
                                        md: 'vertical',
                                        lg: 'horizontal',
                                        xl: 'vertical',
                                        '2xl': 'horizontal'
                                    }}
                                >
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>Status</DescriptionListTerm>
                                        { isLicenseActive &&  <DescriptionListDescription style={{ color: 'green', fontWeight: 'bold'}} >Active</DescriptionListDescription>}
                                        { !isLicenseActive &&  <DescriptionListDescription style={{ color: 'red', fontWeight: 'bold'}} >Not Active</DescriptionListDescription>}
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>Key</DescriptionListTerm>
                                        <DescriptionListDescription>{license?.key}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>App Code</DescriptionListTerm>
                                        <DescriptionListDescription>{license?.appCode}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>App Id</DescriptionListTerm>
                                        <DescriptionListDescription>{license?.applicationId}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>Start Date</DescriptionListTerm>
                                        <DescriptionListDescription>{license?.startDate}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>End Date</DescriptionListTerm>
                                        <DescriptionListDescription>{license?.endDate}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                </DescriptionList>
                            }
                        </CardBody>
                    </Card>
                );
            }
        }
    }

    return (
        <PageSection className="kamelet-section projects-page" padding={{default: 'noPadding'}}>
            <PageSection className="tools-section" padding={{default: 'noPadding'}}>
                <MainToolbar title={title()} tools={getTools()}/>
            </PageSection>
            <PageSection isFilled className="kamelets-page">
                {getLicenseDetails(license)}
            </PageSection>
            {["add"].includes(operation) && <AddLicenseModal/>}
            {["delete"].includes(operation) && <DeleteLicenseModal/>}
        </PageSection>
    )
}