import React, {useState} from 'react';
import {
    Button, Form, FormGroup,
    Modal,
    ModalVariant, Text, TextInput,
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {LicenseService} from "../../api/LicenseService";
import {License} from "../../api/LicenseModels";
import {useLicenseStore} from "../../api/LicenseStore";

export const AddLicenseModal = () => {

    const {license,  operation, setLicense} = useLicenseStore();
    const [key, setKey] = useState('');
    const [isError, setIsError] = useState(false);

    function cleanValues()  {
        setKey("");
    }

    async function closeModal () {
        if(license?.appCode === undefined) {
            useLicenseStore.setState({license: undefined});
        }
        useLicenseStore.setState({operation: "none"});

        const [err, _license] = await LicenseService.getLicense();
        if(err === null && _license !== null) {
            useLicenseStore.setState({license: _license!!, operation: "none"})
        }
        cleanValues();
    }

    async function confirmAndCloseModal () {
        setIsError(false);
        const [ err, license ] = await LicenseService.createLicense(
            new License({key: key})
        );

        if(err !== null) {
            setIsError(true);
        } else if(license !== null) {
            setIsError(false);
            useLicenseStore.setState({operation: "none", license: license});
            cleanValues();
        }
    }

    function onKeyDown (event: React.KeyboardEvent<HTMLDivElement>): void {
        if (event.key === 'Enter' && key !== undefined) {
            confirmAndCloseModal();
        }
    }

    const onKeyChange = (key: string) => {
        setKey(key);
    };

    const isReady = key !== undefined;

    return (
        <Modal
            title="Add new license"
            variant={ModalVariant.small}
            isOpen={["add"].includes(operation)}
            onClose={closeModal}
            onKeyDown={onKeyDown}
            actions={[
                <Button key="confirm" variant="primary" isDisabled={!isReady}
                        onClick={confirmAndCloseModal}>Save</Button>,
                <Button key="cancel" variant="secondary" onClick={closeModal}>Cancel</Button>
            ]}
            className="new-project"
        >
            <Form isHorizontal={true} autoComplete="off">
                <FormGroup label="Key" fieldId="key" isRequired>
                    <TextInput className="text-field" type="text" id="key" name="key"
                               value={key}
                               onChange={e => onKeyChange(e.currentTarget.value)}
                               validated={isError ? 'error' : 'default'}
                    />
                    {isError && <Text  style={{ color: 'red', fontStyle: 'italic'}}>Invalid license key</Text>
                    }
                </FormGroup>
            </Form>
        </Modal>
    )
}