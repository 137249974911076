import axios, {AxiosResponse } from "axios";
import {License} from "./LicenseModels";
import {SsoApi} from "./SsoApi";
import {KaravanApi} from "./KaravanApi";
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
const instance = axios.create();

export class SmhApi {
    static setAuthType(authType: string) {
        console.log("SMHApi: authType", authType);
        switch (authType){
            case "public": {
                SmhApi.setPublicAuthentication();
                break;
            }
            case "oidc": {
                SmhApi.setOidcAuthentication();
                break;
            }
            case "basic": {
                SmhApi.setBasicAuthentication();
                break;
            }
        }
    }
    static setPublicAuthentication() {

    }
    static setBasicAuthentication() {
        instance.interceptors.request.use(async config => {
                config.headers.Authorization = 'Basic ' + KaravanApi.basicToken;
                return config;
            },
            error => {
                Promise.reject(error)
            });
    }
    static setOidcAuthentication() {
        instance.interceptors.request.use(async config => {
                config.headers.Authorization = 'Bearer ' + SsoApi.keycloak?.token;
                return config;
            },
            error => {
                Promise.reject(error)
            });

        instance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if ((error?.response?.status === 403 || error?.response?.status === 401) && !originalRequest._retry) {
                console.log("error", error)
                return SsoApi.keycloak?.updateToken(30).then(refreshed => {
                    if (refreshed) {
                        console.log('SsoApi', 'Token was successfully refreshed');
                    } else {
                        console.log('SsoApi', 'Token is still valid');
                    }
                    originalRequest._retry = true;
                    return instance(originalRequest);
                }).catch(reason => {
                    console.log('SsoApi', 'Failed to refresh token: ' + reason);
                });
            }
            return Promise.reject(error);
        });
    }

    static async getLicense(): Promise<[Error | null, License | null]> {
        return instance.get('/api/smh/license', {})
            .then(res => {
                if (res.status === 200) {
                    return [null, res.data as License] as [null, License]
                } else {
                    return [Error("License fetch failed"), null] as [Error, null]
                }
            }).catch(err => {
            console.log(err);
            return [Error("License fetch failed"), null] as [Error, null]
        });
    }

    static async postLicense(license: License): Promise<[Error | null, License | null]> {
        return instance.post('/api/smh/license', {
            key: license.key
        })
            .then(res => {
                if(res.status === 200 || res.status === 201) {
                    return [null, res.data as License] as [null, License]
                } else if(res.status === 400) {
                    return [Error("Invalid license key."), null] as [Error, null]
                } else {
                    return [Error("Invalid license key."), null] as [Error, null]
                }
            }).catch(err => [err as Error, null] as [Error, null]);
    }

    static async deleteLicense(): Promise<Error | null> {
        return instance.delete('/api/smh/license')
            .then(res => {
                if (res.status === 204 || res.status === 200) {
                    return null;
                } else if(res.status === 403) {
                    return Error("Forbidden Action");
                } else {
                    return null;
                }

            }).catch(err => {
                return Error(err);
            });
    }
}

